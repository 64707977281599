<script setup>
import Dropdown from '@/Components/Dropdown.vue';
import DropdownLink from '@/Components/DropdownLink.vue';
import IcoSpinner from '@/Images/IcoSpinner.vue';
import ResponsiveNavLink from '@/Components/ResponsiveNavLink.vue';
import { onMounted, onUnmounted, ref } from 'vue';
import { router } from '@inertiajs/vue3'
import { signOut } from 'firebase/auth';
import { useCurrentUser, useFirebaseAuth } from 'vuefire';

import { locale } from '@/Localization.js';
import { trans, transChoice } from 'laravel-vue-i18n';

const props = defineProps({
    nav_pos_absolute: { type: Boolean, default: true },
    nav_as_hamburger: { type: Boolean, default: true },
});

const showing_navigation_dropdown = ref(false);
const user = useCurrentUser()
const auth = useFirebaseAuth() // only exists on client side

// TOFIX: Because route() does not exits in Meditation App :/ @see @routes
const route = (name, params) => {
    // console.debug('route', { name: name, params: params });
    var a = {
        'customer.app.index': `/${locale.value}/meditation`,
        'customer.subscriptions.index': `/${locale.value}/meditation/subscriptions`,
        'logout': '/logout',
        'auth.login': `/${locale.value}/login`,
        'xhr.customer.subscriber.get': '/xhr/meditation/subscriber',
    }

    if (a[name]) {
        return a[name];
    }

    console.error('route?', { name: name, params: params });
}

const loaded = ref(null);
const getSubscriber = async () => {
    axios.get(route('xhr.customer.subscriber.get'), {})
        .then((response) => {
            loaded.value = true;
            subscriber.value = response.data;
            have_subscriptions.value = subscriber.value && Object.keys(subscriber.value.subscriptions).length
        })
        .catch(error => {
            // If this .catch is commented, axios while throw an exception bc of the abort().
            // console.log('Error', error);
        });
}

const subscriber = ref(null) // usePage().props.auth.subscriber;
const have_subscriptions = ref(null);

const handleSignOut = () => {
    signOut(auth).then(() => { });
};

const closeOnEscape = (e) => {
    if (showing_navigation_dropdown.value && e.key === 'Escape') {
        showing_navigation_dropdown.value = false;
    }
};

onMounted(() => {
    document.addEventListener('keydown', closeOnEscape);
    getSubscriber();
})

onUnmounted(() => document.removeEventListener('keydown', closeOnEscape));
</script>

<template>
    <!-- Full Screen Dropdown Overlay -->
    <div v-if="nav_pos_absolute" v-show="showing_navigation_dropdown" class="fixed inset-0 z-40" @click="showing_navigation_dropdown = false"></div>

    <!-- Desktop Dropdown Menu -->
    <div class="hidden sm:items-center sm:ml-6" :class="{ 'sm:flex': !nav_as_hamburger }">

        <div class="ml-3 relative">
            <Dropdown align="right" width="48">

                <template #trigger>
                    <span class="inline-flex rounded-md">
                        <button type="button" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-500 dark:text-gray-400 nope-bg-white nope-dark:bg-[#1D2F33] hover:text-gray-700 dark:hover:text-gray-300 focus:outline-none transition ease-in-out duration-150">

                            <div v-if="user" class="flex items-center space-x-4">
                                <img v-if="user.photoURL" class="w-6 h-6 rounded-full" :src="user.photoURL" alt="">
                                <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 rounded-full">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                </svg>
                                <div class="font-medium dark:text-white whitespace-nowrap">
                                    <div v-if="user.displayName">{{ user.displayName }}</div>
                                    <div v-else>{{ trans('Mon Compte') }}</div>
                                </div>
                            </div>

                            <svg class="ml-2 -mr-0.5 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                            </svg>
                        </button>
                    </span>
                </template>

                <template #content>
                    <template v-if="user">
                        <template v-if="loaded">
                            <template v-if="have_subscriptions">
                                <DropdownLink tag="a" :href="route('customer.app.index', { locale: locale })">
                                    {{ trans("Mon espace méditation") }}
                                </DropdownLink>
                                <DropdownLink tag="a" :href="route('customer.subscriptions.index', { locale: locale })">
                                    {{ trans("Mon compte") }}
                                </DropdownLink>
                            </template>
                        </template>
                        <template v-else>
                            <DropdownLink tag="a" href="#">
                                <span class="text-gray-500 pl-2">
                                    <IcoSpinner /> {{ trans("Chargement...") }}
                                </span>
                            </DropdownLink>
                        </template>
                        <DropdownLink href="#logout-auth" @click="handleSignOut()">
                            {{ trans("Déconnexion") }}
                        </DropdownLink>
                    </template>
                </template>
            </Dropdown>
        </div>

    </div>

    <!-- Hamburger -->
    <div class=" top-0 right-0 flex items-center z-50" :class="{ 'relative float-right': !nav_pos_absolute, 'absolute': nav_pos_absolute, 'sm:hidden': !nav_as_hamburger }">
        <button @click="showing_navigation_dropdown = !showing_navigation_dropdown" aria-label="Navigation" class="inline-flex items-center justify-center p-2 rounded-md text-gray-900 dark:text-gray-500 hover:text-gray-900 dark:hover:text-gray-400  focus:outline-none focus:text-gray-900 dark:focus:text-gray-400 transition duration-150 ease-in-out" :class="{ 'bg-white hover:bg-white dark:hover:bg-gray-900 focus:bg-white dark:focus:bg-gray-900': showing_navigation_dropdown, '[&not(:focus)]:bg-transparent !bg-transparent hover:bg-white/50 dark:hover:bg-gray-900 focus:bg-white/80 dark:focus:bg-gray-900': !showing_navigation_dropdown }">
            <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path :class="{
        hidden: showing_navigation_dropdown,
        'inline-flex': !showing_navigation_dropdown,
    }" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                <path :class="{
        hidden: !showing_navigation_dropdown,
        'inline-flex': showing_navigation_dropdown,
    }" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
        </button>
    </div>

    <!-- Responsive Navigation Menu -->
    <div :class="{ 'mb-4': !nav_pos_absolute, block: showing_navigation_dropdown, hidden: !showing_navigation_dropdown, 'sm:hidden': !nav_as_hamburger }" class="bg-white relative top-9 z-50">

        <!-- Responsive Settings Options -->
        <div class="border-t border-gray-200 dark:border-gray-600">
            <div class="space-y-1">
                <template v-if="user">
                    <template v-if="loaded">
                        <template v-if="have_subscriptions">
                            <ResponsiveNavLink tag="a" :href="route('customer.app.index', { locale: locale })">
                                {{ trans("Mon espace") }}
                            </ResponsiveNavLink>
                            <ResponsiveNavLink tag="a" :href="route('customer.subscriptions.index', { locale: locale })">
                                {{ trans("Mon compte") }}
                            </ResponsiveNavLink>
                        </template>
                    </template>
                    <template v-else>
                        <ResponsiveNavLink tag="a" href="#">
                            <span class="text-gray-500 pl-2">
                                <IcoSpinner /> {{ trans("Chargement...") }}
                            </span>
                        </ResponsiveNavLink>
                    </template>
                    <ResponsiveNavLink href="#logout-auth" @click="handleSignOut()">
                        {{ trans('Déconnexion') }}
                    </ResponsiveNavLink>
                </template>
            </div>
        </div>
    </div>
</template>